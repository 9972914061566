import { Button, P1, P2, SafeAreaInsetBottom, Spacer, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import api from "dependencies/api";
import useDeleteBrew from "hooks/useDeleteBrew";
import useGenerateBrew from "hooks/useGenerateBrew";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, setCurrentNewsletter } from "reducers/newslettersReducer";
import useSWR, { mutate } from "swr";
import urls from "urls";
import { fromNowOrDate } from "utils/formatDate";
import notifApiError from "utils/notifApiError";
import { keyBrewById, keyHomeBrews } from "utils/swrKeys";
import UserPrivateBrewHeader, { PrivateBrewHeaderEdit } from "./BrewHeaders/UserPrivateBrewHeader";
import { BrewEditor } from "./editor/BrewEditor";
import LoadingLogo from "./LoadingLogo";
import OwnerBrewIssueViewer from "./OwnerBrewIssueViewer";
import PausedBrewCallout from "./PausedBrewCallout";

export default function Brew({ brew, editMode, setEditMode }) {
  const config = useConfig();
  const dispatch = useDispatch();

  const brewBeingEdited = useSelector(currentNewsletterSelector);
  const [issue, setIssue] = useState(brew.latest_issue);

  const handleDeleteClick = useDeleteBrew(brew);

  const hasIssues = !!brew.latest_issue;
  const hasSources = brew.sources?.length > 0;

  const state = (() => {
    if (editMode && brewBeingEdited) return "editing";
    if (!hasIssues && !hasSources) return "no_sources";
    if (!hasIssues) return "no_issues";
    return "loaded";
  })();

  // set the latest issue when there is no issue set (this happens after generating the first issue)
  useEffect(() => {
    if (brew && brew.latest_issue && !issue) {
      setIssue(brew.latest_issue);
    }
  }, [brew, issue]);

  async function handleStartEdit() {
    try {
      // refetch the brew
      // (using an old brew after some edits performed with "Move source" leads to bugs such as https://github.com/Mailbrew/issues/issues/84)
      const res = await api.get(keyBrewById(brew.id));
      dispatch(setCurrentNewsletter(res.data ?? brew));
      setEditMode(true);
    } catch (err) {
      notifApiError(err);
    }
  }

  function handleEndEdit() {
    mutate(keyHomeBrews);
    setEditMode(false);
  }

  if (state === "no_sources") {
    return (
      <Wrapper key={state}>
        <UserPrivateBrewHeader
          brew={brew}
          issue={issue}
          onStartEditMode={handleStartEdit}
          onIssueChange={(issue) => setIssue(issue)}
        />
        <Stack align="center" style={{ flex: "999 1 auto" }} pb="12vh" vertical gap={2}>
          <P1 align="center">Add some sources.</P1>
          <Button
            onClick={handleStartEdit}
            icon="write"
            color={config.colors.accent1}
            hoverColor={config.colors.accent1}
          >
            Edit this brew
          </Button>
          <P2 mt={2}>
            Or{" "}
            <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleDeleteClick}>
              click here
            </span>{" "}
            to delete this brew.
          </P2>
        </Stack>
      </Wrapper>
    );
  }

  if (state === "no_issues") {
    return <NoIssuesState brew={brew} issue={issue} handleStartEdit={handleStartEdit} />;
  }

  if (state === "editing") {
    return (
      <Wrapper key={state}>
        <PrivateBrewHeaderEdit onEndEditMode={handleEndEdit} />
        <BrewEditor />
      </Wrapper>
    );
  }

  if (state === "loaded") {
    return (
      <Wrapper key={state}>
        <UserPrivateBrewHeader
          brew={brew}
          issue={issue}
          onStartEditMode={handleStartEdit}
          onIssueChange={(issue) => setIssue(issue)}
        />
        {brew.paused && <PausedBrewCallout brew={brew} />}
        {issue && (
          <>
            <OwnerBrewIssueViewer brew={brew} issue={issue} />
            <P2 align="center" color={config.colors.c4} mt={4} mb={3}>
              Sent {fromNowOrDate(issue.publication_date)}
            </P2>
            <SafeAreaInsetBottom />
          </>
        )}
      </Wrapper>
    );
  }

  return null;
}

const NoIssuesState = ({ brew, issue, handleStartEdit }) => {
  const config = useConfig();
  const { data } = useSWR(`/newsletters/${brew.id}/next_issue_date/`);
  const nextIssueDate = data ? fromNowOrDate(data.date) : "...";
  const router = useRouter();

  const handleDeleteClick = useDeleteBrew(brew);

  const { startGeneration, isGenerating } = useGenerateBrew(brew, () => {
    mutate(keyHomeBrews);
    router.replace(urls.brews() + `?brew=${brew.id}`, urls.brews());
  });

  return (
    <Wrapper>
      <UserPrivateBrewHeader brew={brew} issue={issue} onStartEditMode={handleStartEdit} />
      {isGenerating ? (
        <GeneratingAnimation />
      ) : (
        <Stack align="center" style={{ flex: "999 1 auto" }} pb="12vh" vertical gap={1}>
          <P1 align="center">We'll send you the first issue {nextIssueDate} 🍃</P1>
          <Stack vertical align="stretch" mt={3}>
            <Button icon="mailbrew" onClick={startGeneration} disabled={isGenerating}>
              Generate first issue
            </Button>
            <Button
              onClick={handleStartEdit}
              icon="write"
              variant="secondary"
              color={config.colors.accent1}
              hoverColor={config.colors.accent1}
            >
              Keep editing
            </Button>
            <P2 mt={3}>
              Or{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleDeleteClick}>
                click here
              </span>{" "}
              to delete this brew.
            </P2>
          </Stack>
        </Stack>
      )}
    </Wrapper>
  );
};

const GeneratingAnimation = () => {
  return (
    <Stack align="center" style={{ flex: "999 1 auto" }} pb="12vh" vertical gap={1}>
      <LoadingLogo size={50} />
    </Stack>
  );
};

const Wrapper = ({ children }) => {
  const hit = useBreakpoint(640);
  return (
    <Stack w="100%" vertical align="stretch" vAlign="top" h="100%">
      {!hit && <Spacer size={5} />}
      {children}
    </Stack>
  );
};
