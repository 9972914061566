import { P2, useConfig, useDetectBrowser } from "@mailbrew/uikit";
import brewExtraCss from "components/brewExtraCss";
import BrewExtraCssWrapper from "components/BrewExtraCssWrapper";
import useDarkModeListener from "hooks/useDarkModeListener";
import { forwardRef } from "react";
import computeIFrameHeight from "utils/computeIFrameHeight";
import getTemplatesCssVersion from "utils/getTemplatesCssVersion";
import ExternalContentIFrame from "./ExternalContentIFrame";

const BrewIssueViewer = forwardRef(({ html, hideHeaderAndFooter, editHTMLElementBeforeInjection, error }, ref) => {
  const config = useConfig();
  const templateVersion = getTemplatesCssVersion(html);

  // Previous template versions required some padding
  const noPadding = templateVersion > 3;

  const hideHeaderAndFooterCss = `
  #header {
    display: none;
  }
  #footer {
    display: none;
  }
`;

  const css = `
    ${brewExtraCss(config, templateVersion)}
    ${hideHeaderAndFooter ? hideHeaderAndFooterCss : ""}
  `;

  // re-render when dark mode setting changes
  const { isDark } = useDarkModeListener();
  const { isPwa } = useDetectBrowser();
  // ... on mobile Safari (non-pwa) dark mode change is detected when existing and re-entering the browser,
  // causing the brew to scroll top
  const brewContentKey = isPwa ? `${isDark.toString()}` : ``;

  return (
    <BrewExtraCssWrapper templateVersion={templateVersion}>
      {error && (
        <P2 mt={6}>
          Sorry, there was a problem 😕{" "}
          <a href="mailto:support@mailbrew.com?subject=Empty%20Digest%20Content">Contact us</a> for support.
        </P2>
      )}
      <ExternalContentIFrame
        key={`${brewContentKey}${noPadding}`}
        cssString={css}
        html={html}
        ref={ref}
        editHTMLElementBeforeInjection={editHTMLElementBeforeInjection}
        customComputeIFrameHeight={(iframe, extraPadding) =>
          calcBrewIFrameHeight(iframe, extraPadding) ?? computeIFrameHeight(iframe, extraPadding)
        }
        extraPadding={50}
        transparent
        style={noPadding ? {} : { paddingLeft: 16, paddingRight: 16 }}
      />
    </BrewExtraCssWrapper>
  );
});

function calcBrewIFrameHeight(iframe, extraPadding) {
  const document = iframe.contentDocument ? iframe.contentDocument : iframe.contentWindow.document;
  const body = document?.body ? document.body : document?.documentElement;

  const content = body.querySelector(".brew-content");
  if (!content) return;
  const wrapper = content.closest(".main-wrapper");
  if (!wrapper) return;

  return `${wrapper.offsetHeight + extraPadding}px`;
}

export default BrewIssueViewer;
