import {
  Box,
  DropDownMenu,
  Icon,
  P1,
  SmallText,
  Stack,
  Tooltip,
  useBreakpoint,
  useConfig,
  useWindowSize,
} from "@mailbrew/uikit";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { createNewsletter } from "reducers/newslettersReducer";
import apiErrMsg from "utils/apiErrMsg";
import prefers12HoursFormat from "utils/detectTimeFormatPreference";
import { usePaywallState } from "./PaywallStateProvider";

const prefers12Hours = prefers12HoursFormat();

export default function BrewsPicker({ brews, selectedBrewID, onSelectBrew }) {
  const config = useConfig();
  const hit = useBreakpoint(640);
  const dispatch = useDispatch();
  const { setPaywallModalShown } = usePaywallState();

  const { width: _windowWidth } = useWindowSize({ debounceDelay: 15 });

  const windowWidth = Math.min(_windowWidth ?? 0, parseInt(config.layout.width));

  const maxBrews = Math.max(1, brews.filter((b) => !b.paused).length);

  const [cutOffPoint, setCutOffPoint] = useState(maxBrews);

  const cappedCutoffPoint = useMemo(() => {
    return Math.min(cutOffPoint, maxBrews);
  }, [cutOffPoint, maxBrews]);

  const shownBrews = useMemo(() => {
    return brews?.slice(0, cappedCutoffPoint);
  }, [brews, cappedCutoffPoint]);

  const otherBrews = useMemo(() => {
    return brews?.slice(cappedCutoffPoint, brews?.length);
  }, [brews, cappedCutoffPoint]);

  useEffect(() => {
    const lateralSpace = 10;

    // Calculate shown items width
    const shownItemsWidth = computeWidth(shownBrews) + lateralSpace;
    // Already calculate also if there's one more item to shown
    const shownItemsPlusOneWidth = computeWidth(brews?.slice(0, shownBrews.length + 1)) + lateralSpace;

    if (shownItemsWidth > windowWidth) {
      // If items don't fit the screen, reduce cut off point
      setCutOffPoint((prev) => prev - 1);
    } else {
      if (shownItemsPlusOneWidth < windowWidth) {
        // Otherwise increase it
        setCutOffPoint((prev) => prev + 1);
      }
    }
  }, [brews, shownBrews, windowWidth]);

  function handleCreateBrew() {
    dispatch(
      createNewsletter("New brew", (err) => {
        if (err) {
          const msg = apiErrMsg(err) || "";
          if (msg.toLowerCase().includes("upgrade")) {
            setPaywallModalShown(true);
          }
        }
      })
    );
  }

  const dropDownOptions = [
    {
      value: "new",
      icon: "plus",
      name: "New brew",
      handler: handleCreateBrew,
    },
    ...otherBrews.map((brew) => ({
      value: brew.id,
      name: brew.title,
      detail: brew.paused
        ? "Paused"
        : moment(brew.latest_issue?.publication_date).format(prefers12Hours ? "MMM DD, hh:mm A" : "MMM DD, HH:mm"),
      handler: () => onSelectBrew(brew.id),
    })),
  ];

  if (!brews || !selectedBrewID) return null;

  return (
    <Stack gap={2} noWrap align="center">
      <Stack gap={hit ? 2 : 3} noWrap align="center">
        {shownBrews.map((brew) => (
          <BrewCard
            key={brew.id}
            brew={brew}
            selected={brew.id === selectedBrewID}
            onClick={() => onSelectBrew(brew.id)}
          />
        ))}
      </Stack>
      {brews?.length === 1 ? (
        <Tooltip title={shownBrews?.length === 1 && "New brew"}>
          <Icon name={"plus"} color={config.colors.c4} onClick={handleCreateBrew} />
        </Tooltip>
      ) : (
        <DropDownMenu
          options={dropDownOptions.map((o) => o.value)}
          optionsNames={dropDownOptions.map((o) => o.name)}
          optionsIcons={dropDownOptions.map((o) => o.icon)}
          optionsDetails={dropDownOptions.map((o) => o.detail)}
          onSelect={(value) => dropDownOptions.find((o) => o.value === value).handler()}
          icon={"chevronRight"}
          iconColor={config.colors.c4}
          scrollable
          width="230px"
        />
      )}
    </Stack>
  );
}

const BrewCard = ({ brew, selected, onClick }) => {
  const config = useConfig();
  const hit = useBreakpoint(640);

  return (
    <Stack
      as={motion.div}
      animate={{
        paddingLeft: selected ? "8px" : "0px",
        paddingRight: selected ? "8px" : "0px",
      }}
      py="6px"
      transition={{ type: "spring", duration: 0.5, bounce: 0.1 }}
      initial={false}
      background={selected ? config.colors.c6 : "transparent"}
      radius={2}
      vertical
      gap={0}
      style={{ cursor: "pointer", transition: "0.2s background" }}
      onClick={onClick}
    >
      <P1
        noWrap
        color={selected ? config.colors.c1 : config.colors.c3}
        weight="500"
        lineHeight="1.33"
        style={{ zoom: hit ? 0.9 : 1, userSelect: "none" }}
      >
        {brew.title}
      </P1>
      <BrewCardStatus brew={brew} selected={selected} />
    </Stack>
  );
};

const BrewCardStatus = ({ brew, selected }) => {
  const config = useConfig();
  const latestIssue = brew.latest_issue;

  const uiState = (() => {
    if (brew.paused) return "paused";
    if (!brew.latest_issue) return "no_issues";
    else return "default";
  })();

  let labelText = null;
  let labelColor = null;

  if (uiState === "paused") {
    labelText = "Paused";
    labelColor = config.colors.orange;
  } else if (uiState === "no_issues") {
    labelText = "No issues";
    labelColor = config.colors.orange;
  } else {
    const publicationDate = latestIssue.publication_date;
    const isToday = moment(publicationDate).isAfter(moment().subtract(1, "day"), "day");
    const isJustBrewed = moment(publicationDate).isAfter(moment().subtract(1, "hour"), "minute");

    if (isJustBrewed) {
      labelText = "Just brewed";
      labelColor = config.colors.accent2;
    } else {
      labelText = (() => {
        const formattedDate = moment(publicationDate).format("MMM DD");
        const fromNow = moment(publicationDate)
          .fromNow()
          .replace(" minutes", "m")
          .replace(" hours", "h")
          .replace("an hour", "1h");

        return isToday ? fromNow : formattedDate;
      })();
    }
    labelColor = !latestIssue.reading_progress ? config.colors.accent2 : selected ? config.colors.c3 : config.colors.c4;
  }

  return (
    <Stack noWrap overflow="hidden" gap={0}>
      {latestIssue && !latestIssue.reading_progress && <UnreadBadge />}
      <SmallText noWrap lineHeight="1.33" color={labelColor} style={{ userSelect: "none" }}>
        {labelText}
      </SmallText>
    </Stack>
  );
};

const UnreadBadge = ({ ...otherProps }) => {
  const config = useConfig();
  return (
    <Box
      w="8px"
      h="8px"
      radius="4px"
      mr="6px"
      position="relative"
      bottom="-1px"
      color={config.colors.accent2}
      background={config.colors.accent2}
      shadow={`inset 0px 0px 0px 2px ${config.colors.accent2}`}
      {...otherProps}
    />
  );
};

function computeWidth(brews) {
  return brews?.map((brew) => brew.title).join("__").length * 8.3;
}
