import { useLocalStorage } from "@mailbrew/uikit";
import { useDispatch, useSelector } from "react-redux";
import useCurrentTimezone from "../hooks/useCurrentTimezone";
import { authUserSelector } from "../reducers/authReducer";
import { updateUser } from "../reducers/settingsReducer";
import HomeBanner from "./HomeBanner";

const TimezoneDetector = ({ background }) => {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);

  const currentTimezone = useCurrentTimezone();
  const [savedTimezone, setSavedTimezone] = useLocalStorage("saved-timezone");

  function handleChangeTimezone() {
    dispatch(updateUser({ first_name: user.first_name, last_name: user.last_name, timezone: currentTimezone }));
  }
  function handleKeepCurrent() {
    setSavedTimezone(user.timezone);
  }

  function formatTimezone(timezone) {
    return timezone.replace("_", " ");
  }

  if (currentTimezone && user) {
    const userTimezone = user.timezone;

    if (currentTimezone === userTimezone || userTimezone === savedTimezone) {
      return null;
    } else {
      return (
        <HomeBanner
          icon="worldAltBold"
          html={`Your saved timezone is <strong style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${formatTimezone(
            userTimezone
          )}</strong>`}
          buttons={[
            {
              onClick: () => handleChangeTimezone(),
              label: `Set ${formatTimezone(currentTimezone)}`,
            },
            {
              onClick: () => handleKeepCurrent(),
              label: `Keep current`,
            },
          ]}
          background={background}
        />
      );
    }
  } else {
    return null;
  }
};

export default TimezoneDetector;
