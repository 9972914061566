import { Button, P1, P2, Section, Stack, SubtleCard } from "@mailbrew/uikit";
import { usePaywallState } from "./PaywallStateProvider";

export default function PostsLimitBanner() {
  const { setPaywallModalShown } = usePaywallState();
  return (
    <Section width="720px" noPadding>
      <SubtleCard background="hsl(40, 100%, 40%)" mt={-6} mb={8}>
        <Stack my={2} gap={1} vertical align="center">
          <P1 align="center">You hit the free posts limit</P1>
          <P2 align="center" mb={3}>
            Upgrade to get unlimited content and brews, and use Pro features 🔥
          </P2>
          <Button icon="lockOff" onClick={() => setPaywallModalShown(true)}>
            Remove limit
          </Button>
        </Stack>
      </SubtleCard>
    </Section>
  );
}
