function getTemplatesCssVersion(content) {
  let templateVersion = 1;
  if (content) {
    if (typeof content === "string") {
      templateVersion = !!(content?.indexOf("template-v2") > -1) ? 2 : templateVersion;
      templateVersion = !!(content?.indexOf("template-v3") > -1) ? 3 : templateVersion;
      templateVersion = !!(content?.indexOf("template-v4") > -1) ? 4 : templateVersion;
      templateVersion = !!(content?.indexOf("template-v5") > -1) ? 5 : templateVersion;
    } else {
      templateVersion = !!content.querySelector(".template-v2") ? 2 : templateVersion;
      templateVersion = !!content.querySelector(".template-v3") ? 3 : templateVersion;
      templateVersion = !!content.querySelector(".template-v4") ? 4 : templateVersion;
      templateVersion = !!content.querySelector(".template-v5") ? 5 : templateVersion;
    }
  }
  return templateVersion;
}

export default getTemplatesCssVersion;
