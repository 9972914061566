import { Button, P1, Stack, SubtleCard } from "@mailbrew/uikit";
import api from "dependencies/api";
import useGenerateBrew from "hooks/useGenerateBrew";
import moment from "moment";
import { mutate } from "swr";
import notifApiError from "utils/notifApiError";
import { keyBrewById, keyHomeBrews } from "utils/swrKeys";
import { BrewSection } from "./BrewHeaders/UserPrivateBrewHeader";

export default function PausedBrewCallout({ brew }) {
  const { startGeneration, isGenerating } = useGenerateBrew(
    brew,
    () => {
      mutate(keyHomeBrews);
    },
    {
      notifMessage: "Generating a fresh issue...",
    }
  );

  async function handleResume() {
    const latestPublicationDate = moment(brew?.latest_issue?.publication_date);
    const latestIssueIsOld =
      latestPublicationDate.isValid() && latestPublicationDate.isBefore(moment().subtract(10, "days"), "day");

    try {
      await api.patch(keyBrewById(brew.id), { paused: false });
      if (latestIssueIsOld && !isGenerating) {
        startGeneration();
      }
      mutate(keyHomeBrews);
      mutate(keyBrewById(brew.id));
    } catch (err) {
      notifApiError(err);
    }
  }

  return (
    <BrewSection>
      <SubtleCard mb={4}>
        <Stack vertical align="center">
          <P1 align="center">This brew is paused, start receiving it again.</P1>
          <Button icon="playBold" onClick={handleResume}>
            Resume this brew
          </Button>
        </Stack>
      </SubtleCard>
    </BrewSection>
  );
}
