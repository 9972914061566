import { Button, Center, H1, P1, P2, Section, Stack, useConfig } from "@mailbrew/uikit";
import Brew from "components/Brew";
import BrewsPicker from "components/BrewsPicker";
import LoadingLogo from "components/LoadingLogo";
import Page from "components/Page";
import StyledA from "components/StyledA";
import TimezoneDetector from "components/TimezoneDetector";
import api from "dependencies/api";
import loggedInPage from "hoc/loggedInPage";
import usePrevious from "hooks/usePrevious";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { selectedBrewIDState } from "recoilState";
import { authUserSelector } from "reducers/authReducer";
import { createNewsletter } from "reducers/newslettersReducer";
import useSWR, { mutate } from "swr";
import { keyHomeBrews, keyIssueById } from "utils/swrKeys";

const BrewsPage = () => {
  const router = useRouter();
  const { brew: urlBrewID } = router.query;
  const { navigateToLatestIssue } = router.query ?? {};
  const [editMode, setEditMode] = useState(false);
  const [selectedBrewID, setSelectedBrewID] = useRecoilState(selectedBrewIDState);
  const prevSelectedBrewID = usePrevious(selectedBrewID);

  const user = useSelector(authUserSelector);
  const dispatch = useDispatch();

  const { data: brews, error: brewsError } = useSWR(keyHomeBrews, {
    revalidateOnFocus: !editMode,
    refreshInterval: 300_000, // 5 mins
  });

  const selectedBrew = useMemo(() => {
    if (!brews) return null;
    if (brews && selectedBrewID) return brews.find((brew) => brew.id === selectedBrewID);
    return null;
  }, [brews, selectedBrewID]);

  // select brew from url if provided
  useEffect(() => {
    if (urlBrewID) {
      setSelectedBrewID(parseInt(urlBrewID));
    }
  }, [brews, urlBrewID, setSelectedBrewID]);

  // update reading progress when selecting a brew
  useEffect(() => {
    if (selectedBrew && selectedBrew.latest_issue && !selectedBrew.latest_issue.reading_progress) {
      const switchedBrew = prevSelectedBrewID !== selectedBrewID;

      api.patch(keyIssueById(selectedBrew.latest_issue.id), { reading_progress: 0.01 }).then();

      const t = setTimeout(
        async () => {
          mutate(
            keyHomeBrews,
            (cache) => {
              const v = cache.map((brew) => {
                if (brew.id === selectedBrew.id && brew.latest_issue)
                  return { ...brew, latest_issue: { ...brew.latest_issue, reading_progress: 0.1 } };
                return brew;
              });
              return v;
            },
            false
          );
        },
        // Add a couple seconds delay to show the current issue as unread when the user opened the app directly on that brew.
        // When manually switching brews, immediately update the read state instead.
        switchedBrew ? 0 : 2000
      );

      return () => clearTimeout(t);
    }
  }, [prevSelectedBrewID, selectedBrew, selectedBrewID]);

  // update the selected brew
  useEffect(() => {
    if (!brews || brews.length === 0) return;

    const shouldSelectFirstBrew =
      // there is no brew currently selected
      !selectedBrewID ||
      // navigateToLatestIssue specified via url parameter
      navigateToLatestIssue ||
      // the current selectedBrewID is not in the brews (e.g. brew delete)
      !brews.find((brew) => brew.id === selectedBrewID);

    if (shouldSelectFirstBrew) {
      setSelectedBrewID(brews[0].id);
    }
  }, [brews, navigateToLatestIssue, selectedBrewID, setSelectedBrewID]);

  const handleSelectBrew = useCallback(
    (brewID) => {
      setSelectedBrewID(brewID);
      setEditMode(false);
    },
    [setSelectedBrewID]
  );

  const uiState = (() => {
    // only go in the error state when there are no brews, to avoid disruption while reading loaded brew https://github.com/Mailbrew/issues/issues/59
    if (brewsError && !brews) return "error";
    if (!brews) return "loading";
    if (brews.length === 0) return "no_brews";
    else return "loaded";
  })();

  if (["no_brews", "error", "loading"].includes(uiState)) {
    return (
      <PagePrivate fullTitle="Mailbrew">
        <TimezoneDetector />
        <Section center>
          {uiState === "no_brews" && (
            <>
              <H1 align="center" mb={1}>
                Hey, {user.first_name} 👋
              </H1>
              <P1 align="center" mb={5}>
                Create your first digest.
              </P1>
              <Button glow icon="mailbrew" onClick={() => dispatch(createNewsletter("New brew"))}>
                Create a brew
              </Button>
            </>
          )}
          {uiState === "loading" && <LoadingLogo />}
          {uiState === "error" && (
            <Stack vertical align="center">
              <P2 align="center">There was an error loading your brews 😕</P2>
              {brewsError && <P2 align="center">{brewsError?.toString()}</P2>}
              <Stack align="center" noWrap>
                <Button variant={["secondary", "pill"]} icon="refresh" onClick={() => window.location.reload()}>
                  Reload
                </Button>
                <StyledA
                  variant={["secondary", "pill"]}
                  icon="email"
                  href="mailto:support@mailbrew.com?subject=Error%20loading%20brews"
                >
                  Contact us
                </StyledA>
              </Stack>
            </Stack>
          )}
        </Section>
      </PagePrivate>
    );
  }

  return (
    <PagePrivate
      headerComponents={
        brews.length > 0 && (
          <BrewsPicker brews={brews} selectedBrewID={selectedBrewID} onSelectBrew={handleSelectBrew} />
        )
      }
      fullTitle={"Mailbrew"}
    >
      <Section
        width="100%"
        mt={0}
        style={{
          flex: "9999 0 auto",
        }}
        noLateralPadding
        center
      >
        <TimezoneDetector />
        <Center style={{ flex: 1 }}>
          {uiState === "loaded" && selectedBrew && (
            <Brew key={selectedBrewID} brew={selectedBrew} editMode={editMode} setEditMode={setEditMode} />
          )}
        </Center>
      </Section>
    </PagePrivate>
  );
};

const PagePrivate = ({ children, fullTitle, ...otherProps }) => {
  const config = useConfig();
  return (
    <Page
      fullTitle={fullTitle}
      stickyHeader
      background={config.colors.bg0}
      showMonetizationBanner
      showTimedOffer
      {...otherProps}
    >
      {children}
    </Page>
  );
};

export default loggedInPage(BrewsPage);
