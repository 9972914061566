import { Box, Button, HStack, IconBadge, P2, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { Fragment } from "react";
import StyledLink from "./StyledLink";

const HomeBanner = (props) => {
  const { icon, color: providedColor, background, html, links, buttons } = props;

  const breakpointHit = useBreakpoint();

  const config = useConfig();

  const color = providedColor || config.colors.c2;

  return (
    <HStack
      align="center"
      pt={breakpointHit ? 3 : 1}
      pb={4}
      pl={config.layout.padding}
      pr={config.layout.padding}
      gap={1}
      breakAt={640}
      style={{
        zoom: breakpointHit ? 0.9 : 1,
      }}
      background={background}
    >
      <HStack noWrap align="center">
        {!breakpointHit && <IconBadge name={icon} size="26px" color={color} />}
        <P2 style={{ flex: "0 1 auto" }} color={color} align={breakpointHit ? "center" : "left"}>
          <span dangerouslySetInnerHTML={{ __html: html }} />
          &nbsp;&nbsp;
        </P2>
      </HStack>
      <HStack noWrap>
        {links &&
          links.map((link, i) => (
            <Fragment key={i}>
              <StyledLink style={{ margin: "0 .2em 0" }} variant={["white", "small"]} to={link.to}>
                {link.label}
              </StyledLink>
              <P2 px={2} style={{ flex: "0" }} color={color}>
                {i < links.length - 1 && "or"}
              </P2>
            </Fragment>
          ))}
        {buttons &&
          buttons.map((button, i) => (
            <Fragment key={i}>
              <Button variant={["small", "white"]} onClick={button.onClick}>
                {button.label}
              </Button>
              {i < buttons.length - 1 &&
                (breakpointHit ? (
                  <Box w="10px" />
                ) : (
                  <P2 px={2} style={{ flex: "0" }} color={color}>
                    or
                  </P2>
                ))}
            </Fragment>
          ))}
      </HStack>
    </HStack>
  );
};

export default HomeBanner;
